.wrapper {
  align-items: stretch;
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;

  &:before {
    width: 100%;
    height: 264px;
    position: absolute;
    top: 0;
    left: 0;
//     background: rgb(0,119,139);
// background: linear-gradient(131deg, rgba(0,119,139,1) 1%, rgba(0,44,51,1) 100%);
    content: " ";
  }
}