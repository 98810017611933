$primary: #00778b;
$blue: #5c59ed;
$indigo: #3f51b5;
$purple: #a877b5;
$pink: #e91e63;
$red: #f26666;
$danger: #eb3c3c;
$orange: #ff9800;
$yellow: #f2b51d;
$green: #38c485;
$teal: #009688;

// Theme variables
@import "./assets/scss/1-variables/app";

$ring-light: #5ecc98;
$ring-light: #8be6c0;
$ring-light: #14e48d;

.modal-backdrop {
  z-index: 1 !important;
}

.text-accent {
  color: #87fcfc !important;
}

.text-warning-2 {
  color: #f7d21a;
}

.btn-primary-light {
  background-color: $primary-light !important;
  color: $primary;
}

.btn-warning-light {
  background-color: rgba($color: $yellow, $alpha: 0.7) !important;
}

.btn-warning-light-2 {
  background-color: rgba($color: $yellow, $alpha: 0.25) !important;
}

.btn-success-light {
  background-color: rgba($color: $success, $alpha: 1) !important;
  color: $green;
}

.btn-danger-light {
  background-color: $red-light !important;
  color: $red;
}

.ngx-toastr.toast-success {
  background-color: rgba($color: $success, $alpha: 1) !important;
}

.px_10 {
  padding: 0px 10px !important;
}

.px_30 {
  padding: 0px 30px !important;
}

.mb-2 {
  margin-bottom: 5px;
}

.mb-3 {
  margin-bottom: 10px;
}

.ngx-toastr.toast-primary {
  background-color: rgba($color: $primary, $alpha: 1) !important;
}

.ngx-toastr.toast-warning {
  background-color: rgba($color: $yellow, $alpha: 1) !important;
}

.ngx-toastr.toast-danger {
  background-color: rgba($color: $success, $alpha: 1) !important;
}

.text-dark-grey {
  color: #333333 !important;
}

.text-grey {
  color: #5c5c5c;
}

.cursor-pointer {
  cursor: pointer;
}

.text-black {
  color: black !important;
}

.bg-dark {
  color: rgb(238, 238, 238);
}

.bg-corp {
  background-color: $primary !important
}
.bg-lightDark {
  background-color: #e9ecef !important;
}

.text-lightt {
  color: rgb(175, 175, 175) !important;
}

.inside-button {
  margin: 20px;
  padding: 20px;
}

.hide{
  display: none
}

/*matTable*/
.mdc-data-table__header-cell{
  color: white !important;
  background-color: $primary !important;
  font-weight: bold !important;
}


.button-compound{
    border-radius: 5px;
    overflow: hidden;
    width: fit-content;
    height: fit-content;
}

.mh-300 {
  min-height: 300px !important;
}

.header {
  min-height: 150px;
}

.alert-danger {
  width: fit-content !important;
  max-width: 100%;
}

.sidebar-brand {
  background: #00778b;
  background: linear-gradient(
    275deg,
    rgb(1, 106, 124) 1%,
    rgb(1, 72, 83) 100%
  ) !important;
}

.text-logo-icon {
  letter-spacing: 14px;
}

.bg-accent2 {
  background-color: #00c3b9a6;
}

.bg-primary-lightt {
  background-color: #1ab3ce69;
}

.bg-solar {
  background-color: #f4e771bc;
}

.bg-battery {
  background-color: #f7c559c7;
}

.bg-accent {
  background-color: #6df0c9c7;
}

app-admin-layout .wrapper:before {
  background: #00778b;
  background: linear-gradient(
    275deg,
    rgb(1, 72, 83) 1%,
    rgb(1, 106, 124) 100%
  ) !important;
}

app-developer-layout .wrapper:before {
  background: #00778b;
  background: linear-gradient(
    275deg,
    rgb(1, 72, 83) 1%,
    rgb(1, 106, 124) 100%
  ) !important;
}

.button-group {
  border: 1px solid #ccc;
  border-radius: 3px;
}

.w-xl-50 {
  width: 100% !important;
}

.sm-card {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  box-shadow: 5px 5px 11px -3px #d6d6d693;
}

.hr-primary {
  border-top: 1px solid $primary;
  width: 100%;
  opacity: 100% !important;
  height: 0px !important;
}

.card_body_state {
  padding-top: 15px;
  padding-bottom: 5px;
  border-top: 2px solid rgba($color: $primary, $alpha: 0.3);
  border-right: 1px solid rgba($color: $primary, $alpha: 0.1);
  border-top-right-radius: 5px;
}

.text-bold {
  font-weight: bold;
}

.row_header_connector {
  border-bottom: 1px solid $primary;
}

.bg-right-primary {
  border-right: 1px solid $primary;
}

.sm-card h4 {
  font-size: 14px;
}

@media (min-width: 1440px) {
  .w-xl-50 {
    width: 50% !important;
  }
}

@media (max-width: 1440px) {
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
}

.text-light {
  color: #bebebe !important;
}

.mh-80vh {
  min-height: 80vh;
}

.cdk-overlay-container {
  z-index: 1060;
}

.image-body-map-card {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.accordion-button:not(.collapsed) {
  color: #00778b !important;
}

.accordion-button:focus {
  border-color: #00778b !important;
}

.bg-primary-light {
  background-color: #d9f1f5;
}

.bg-yellow {
  background-color: #f0d966 !important;
}

.bg-orange {
  background-color: #fcac48 !important;
}
.bg-green {
  background-color: #38c485 !important;
}

.bg-blue-accent {
  background-color: #56dae0;
}

.btn-primary-hard {
  background-color: $primary !important;
}

.btn-danger-hard {
  background-color: $danger !important;
}

.container-rings-device {
  position: relative;
  height: 220px;
}

.badge-grey {
  background-color: grey;
}

.container-rings-device .ring-outside {
  margin: auto;
  width: 350px !important;
  height: 175px !important;
}

.card-historics-spec {
  min-height: 400px;
}

.historics-spec {
  min-height: 400px;
}

.container-rings-device {
  display: flex;
  justify-content: center;
  align-items: center;
}

.devices-sidebar {
  // min-height: 55vh;
  // max-height: 53vh;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
}

.chartjs-size-monitor {
  width: fit-content !important;
  height: fit-content !important;
}

.chart canvas {
  max-width: none !important;
}

.container-rings-device .ring-inside {
  margin: auto;
  width: 250px !important;
  height: 125px !important;
}

.container-rings-device .chart {
  position: absolute;
  display: flex;
  align-items: center;
  width: 500px;
  justify-content: center;
}

.h_300 {
  height: 20vw;
}

.cog-device-set {
  font-size: 20px;
}

.drowpdown-menu-settings-device {
  top: 50px !important;
  left: -100px !important;
}

.minh-100 {
  min-height: 100%;
}

.image-profile i {
  font-size: 60px;
}

.graph-consumptions-big {
  width: 500px;
  height: 300px;
}

.modal-card-devices {
  width: 1000px;
}

.modal i {
  cursor: pointer;
}

.main-panel {
  background: var(--background-main) !important;
}

app-admin-layout .wrapper:before {
  background: var(--background-wrapper) !important;
  width: 100%;
  height: 264px;
  position: absolute;
  top: 0;
  left: 0;
}

app-developer-layout .wrapper:before {
  background: var(--background-wrapper) !important;
  width: 100%;
  height: 130px;
  position: absolute;
  top: 0;
  left: 0;
}

// // OPTIONS BODY
// @if $bodyBackground == true{
//     .main-panel{
//         background: rgb(0,119,139);
//         background: linear-gradient(131deg, rgba(0,119,139,1) 1%, rgba(0,44,51,1) 100%) !important;
//     }

//     app-admin-layout .wrapper:before{
//         background: none !important;
//     }
// }

@media (max-width: 768px) {
  .modal-card-devices {
    width: 90%;
  }
  .h_300 {
    height: auto;
  }
}

//MEDIA QUERIES MOBILE

@media (max-width: 576px) {
}

// TOGGLE SWITCH
$multiplier: 0.75;
.switch {
  position: relative;
  display: inline-block;
  width: 60px * $multiplier;
  height: 34px * $multiplier;
  border: none !important;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px * $multiplier;
  width: 26px * $multiplier;
  left: 4px * $multiplier;
  bottom: 4px * $multiplier;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px * $multiplier);
  -ms-transform: translateX(26px * $multiplier);
  transform: translateX(26px * $multiplier);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px * $multiplier;
}

.slider.round:before {
  border-radius: 50%;
}

//SMALL
$multiplier: 0.55;
.switch-sm {
  position: relative;
  display: inline-block;
  width: 60px * $multiplier;
  height: 34px * $multiplier;
  border: none !important;
}

/* Hide default HTML checkbox */
.switch-sm input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch-sm .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-sm .slider:before {
  position: absolute;
  content: "";
  height: 26px * $multiplier;
  width: 26px * $multiplier;
  left: 4px * $multiplier;
  bottom: 4px * $multiplier;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-sm input:checked + .slider {
  background-color: $primary;
}

.switch-sm input:focus + .slider {
  box-shadow: 0 0 1px $primary;
}

.switch-sm input:checked + .slider:before {
  -webkit-transform: translateX(26px * $multiplier);
  -ms-transform: translateX(26px * $multiplier);
  transform: translateX(26px * $multiplier);
}

/* Rounded sliders */
.switch-sm .slider.round {
  border-radius: 34px * $multiplier;
}

.switch-sm .slider.round:before {
  border-radius: 50%;
}

/*SPL*/
/*progress bar*/
.progressRange{
  height: 3rem !important;
  background-color: #b5b5b5 !important;
  border: 1px solid black;
  border-radius: 10px !important;
  font-size: 2rem !important;
  font-weight: bold !important;
}
.progressRange span{
  color: white;
  font-weight: 600;
  position: absolute;
  display: block;
  left: 49%;
  left: calc(50% - 12px);
}
.progressOverload{
  height: 3rem !important;
  background-color: #b5b5b5!important;
  border: 1px solid black;
  border-radius: 10px !important;
  font-size: 2rem !important;
  font-weight: bold !important;
}
.progressOverload .progress-bar{
  background-color: #cc0000 !important;
  border: 1px solid #cc0000;
}
.progressOverload span{
  color: white;
  font-weight: 600;
  position: absolute;
  display: block;
  left: 49%;
  left: calc(50% - 12px);
}
.progressRecover{
  height: 3rem !important;
  background-color: #b5b5b5!important;
  border: 1px solid black;
  border-radius: 10px !important;
  font-size: 2rem !important;
  font-weight: bold !important;
}
.progressRecover .progress-bar{
  background-color: #f1c232 !important;
}

.progressRecover span{
  color: white;
  font-weight: 600;
  position: absolute;
  display: block;
  left: 49%;
  left: calc(50% - 12px);
}

/*text*/
.textOverload{
  color: #cc0000 !important;
  font-weight: bold;
}
.textRecover{
  color: #f1c232 !important;
}

/*circle*/
.circleRange{
  width: 100px;
  line-height: 100px;
  border-radius: 50%;
  background-color:#00778b;
  border: 1px solid #000;
  color: white;
  text-align: center;
}
.circleOverload{
  width: 100px;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
  float: left;
  background-color: #cc0000 !important;
  color: white;
}
.circleRecover{
  width: 100px;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
  float: left;
  background-color: #f1c232 !important;
  color: white;
}
.mat-table {
  overflow-x: auto;
  display: block;
}

  /*RFID*/
	@keyframes shutter-in-top {

		0% {
			transform:rotateX(-100deg);
			transform-origin:top;
			opacity:0;
		}
		100% {
			transform:rotateX(0deg);
			transform-origin:top;
			opacity:1;
		}
	}
  .fadeTop {
		animation-name: shutter-in-top;
		animation-duration: 0.5s;
		animation-timing-function: linear;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;
	}

mat-header-cell, mat-cell {
  display:flex;
  justify-content:center;
 }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
