.nav-link-sm {
  padding: .25rem .5rem;
}

.nav-link-lg {
  padding: .75rem 1.5rem;
}

.navbar {
  background-color: $primary;
}