.footer {
  padding: $spacer calc($spacer / 2);

  @include media-breakpoint-up(lg) {
    padding: $spacer calc($spacer * 1.5);
  }

  ul {
    margin-bottom: 0;
  }
}
